<template>
  <div class="poundage">
    <!-- 服务条款 -->

    <Head />
    <div class="main">
      <h2>Terms of service</h2>
      <h3>Terms of Use</h3>
      <p> To protect your rights and interests, please read this User Terms (referred to as "Terms") in detail before
        registering on RAKUMART (referred to as "The Platform" “We” hereinafter) to ensure that you fully understand the
        conditions of the Terms and choose to accept or not.</p>
      <p class="mar40"> You may not access or use the services covered herein unless you have read and accepted the Terms.
        By
        accessing
        and
        use of The Platform and services, you agree to accept and be bound by the Terms.  </p>
      <h3>User Registration</h3>
      <p> 1.Users need to register a "RAKUMART" account before fully accessing the services offered by this platform. The
        account isnot supposed to be registered or banned by RAKUMART.However, we can change the way of account
        registration
        and binding, without prior notice, based on the user’s demands or product requirements.</p>
      <p> 2.You agree that we have the right to use and share the personal information you used to register. In this
        sense, we
        have the right to collect, share and use your personal information within certain limitations once you have
        completed the registration process.</p>
      <p class="mar40"> 3.By accessing or using of The Platform, users agree that we may collect personal information with
        or without
        notification in order to provide better services.</p>
      <h3>User Instructions</h3>
      <p> Users shall comply with applicable laws and regulations of China and Your Country (referred to as” Both
        Countries”),
        including without limitation applicable laws and regulations with respect to the Criminal Law, the National
        Security
        Law, the Confidentiality Law, the Security Protection Regulations of Computer Information System, etc., to protect
        Both Countries’ interests and safeguard their security. Moreover, users shall bear all responsibility caused by
        illegal internet use.</p>
      <p> 1.Usersshall not use RAKUMARTto send or spread sensitive information violating the applicable laws and
        regulations
        of Both Countries.</p>
      <p> 2.Users shall not violate any of the applicable laws and regulations when usingRAKUMART. If anything abnormal
        was
        found, we would provide full assistance in the investigation at the request of the government. If RAKUMART
        reasonably believes that any of your conduct violates or threatens to violate any applicable laws and regulations,
        We would take immediate action at the request of Both Countries.</p>
      <p> 3.A set of Member IDsand password is unique to a single account. Each Member shall be solely responsible for
        maintaining the confidentiality and security of your Member ID and password and for all use of and activities that
        occur under your account (whether such use or activities are authorized or not). No Member may share, assign, or
        permit the use of your Member account, ID, or password by another individual, even to other individuals within the
        Member’s own business entity (where applicable).If RAKUMART believes that the active user is not the original
        registrant of the account, RAKUMART may, at its sole discretion, at any time take action as it may deem
        appropriate
        in light of the circumstances, including, but not limited to, retrieving your account. Users are suggested not to
        transfer their accounts out of any personal interests, in order to avoid disputes arising from account problems.
      </p>
      <p> 4.By accessing and use of services, you shallstrengthen the awareness of protecting personal information, and
        take
        reasonable measures to ensure the security of your password and account.</p>
      <p> 5.Users shall be responsible for the authenticity, legality ,and validity of the registered information. Users
        are
        forbidden to use other’s accountsand impersonate any person or entityto release any information, otherwise, the
        platform has the right to immediately terminate services, and retrieve involved accounts. Users should bear all
        legal responsibilities solely.</p>
      <p>6.Each member agrees to indemnify RAKUMART to hold us harmless, from any and all damages, losses, claims and
        liabilities (including legal costs on a full indemnity basis) on the user itself and the third party, which may
        arise from your access to or use of the platform or services</p>
      <p> 7.It is illegal to steal other's accountsor use the internet to harass others. Any unlawfulmeans like testing
        and
        fraud is forbidden to be taken advantage as excuses to steal account or do harassment.</p>
      <p class="mar40">8.RAKUMARThas the right to, without prior notice, delete the information released on the Platform
        inviting suspicion
        involved in violation of others’ legitimate rights and the applicable laws and regulations. If RAKUMART has
        reasonable grounds to believe that a Member is in breach of any Terms, RAKUMART reserves the right to take
        disciplinary actions as it deems appropriate.

      </p>
      <h3>Intellectual Property Protection</h3>
      <p> 1.RAKUMART is the sole owner or lawful licensee of all the rights and interests, who is protected by
        international
        copyright treaties and other intellectual property laws and treaties. While users have the right to use the
        platform.</p>
      <p> 2.Users shall not use the platform to conduct anyillegal activities or activities that infringe the rights or
        undermine the interests of others, and shall not illegally obtain or use the information of other users on the
        platform.</p>
      <p> 3.Users can only use the platform for non-commercial purposes and do not use, copy, sell, investigate, advertise
        in
        a commercial manner.</p>
      <p> 4.Users shall not technically attack, destroy or change the platform to interfere with its operation; nor
        acquire or
        use any code or other technologies as well as commercial information of the platform in an illegal way; and shall
        not reverse engineer, reverse compile, reverse assemble or rewrite any program run on the platform.</p>
      <p> 5.Users shall use the Platform at their own risk. To the maximum extent permitted by applicable laws, we shall
        in no
        case be liable for compensation for indirect losses and incidents caused by the use or inability to use the
        Platform, even if users have been informed of the possibility of such damages in advance.
      </p>
      <p class="marleft"> Each Member further agrees that RAKUMART is not responsible, and shall have no liability to you
        for your access
        and
        use of RAKUMART,</p>
      <p> including some special, accidental, and indirect losses, to the maximum amount permitted under
        the
        applicable law.</p>
      <p class="mar40"> 6.If you have breached theTerms, we are authorized to immediately terminate theTerms, and reserve
        rights to
        pursue
        the responsibility through legal means.


      </p>

      <h3>Privacy Protection</h3>
      <p> 1.Users should be aware and agree thatto offer better services, the platform will collect and integrate your
        personal informationon the grounds that you consent to use the service or provide the information unless the
        national laws, regulations ,and policies have other provisions and requirements.</p>
      <p> 2.Respect for users' privacy is one of our basic policies. Therefore, The Platform guarantees that we will not
        disclose, edit or share the user's registration data and private informationkept in the platform to the third
        party
        without the explicit authorization of the legal user. <span
          style="font-size: 16px;
                                                                                                                                                                                    font-family: Roboto-Regular, Roboto;
                                                                                                                                                                                    font-weight: 400;
                                                                                                                                                                                    color: #222222;
                                                                                                                                                                                    line-height: 24px;">However,
          on
          the
          basis
          of
          integrity
          and
          honesty, the platform believes that it is necessary to disclose users’ personal information under the following
          five circumstances:</span></p>
      <p class="marleft">·Get the explicit user authorization in advance;</p>
      <p class="marleft">·RAKUMART reserves the right to cooperate fully with governmental or regulatory authorities in
        the investigation of
        any suspected criminal or civil wrongdoing;</p>
      <p class="marleft">·Safeguard the intellectual property rights and other important rights of the platform;</p>
      <p class="marleft">·
        Protect the interests of the public;</p>
      <p class="marleft" style="margin-bottom: 20px;">·Other circumstances mentioned in the Terms.</p>
      <p>3. The platform may cooperate with a third party to offer service, in that case, we will provide your personal
        information for them only if they agree to assume the responsibility of protecting your privacy.</p>
      <p> 4. If we receive complaints and reasonably believeyour violation of the Terms, we reservethe right to review and
        delete relevant contents without prior notice, including, without limitation, user information and posting
        records.
        The violation accounts will face punishments including warning, account suspension ,and service
        termination depending on the seriousness. Users will be notified of the results.</p>
      <p>
        5. Users who are banned by RAKUMART due to violation ofthe Termscan contact the RAKUMART. In the
        meanwhile, the banned function will be available to users after a time limit. Punished users may submit an appeal,
        after then, we will review the appeal and decide whether to change the punishment measures.</p>
      <p>
        6. The user should be aware and consent to, that we have the right to punish users violating relevant laws and
        regulations or this Termbased on reasonable judgment, take appropriate legal actions against users who violate the
        law and regulations, and report it to relevant departments according to the laws and regulations. The user shall
        bear all legal responsibilities.</p>
      <p class="mar40"> 7. You will indemnify and hold RAKUMART and our affiliates,harmless from any claim or demand,
        including reasonable
        attorneys' fees, made by any third parties arising out of or caused by your breach of theTerms.
      </p>
      <h3>Disclaimer</h3>
      <h4>1.The platform is not responsible and shall not have any liability hereunder:</h4>
      <p>· Damages of fragile items, including, without limitation, ceramics, glass, wood, plastic, marble, cosmetics,
        home
        appliances, furniture, etc. But the platform is willing to provide assistance to the user to negotiate a refund,
        while the ultimate choice goes to the seller itself.</p>
      <p>· Problems caused by natural deterioration, such as mold, mildew,
        rust, and fading.</p>
      <p>· Any quality problems, damages, and losses caused by natural disasters, strikes, riots, war, and
        diseases.</p>
      <p>· Packages damaged, or slightly crumpled and cracks in the product that will not affect the use of the
        product.</p>
      <p>· The case that goods received are inconsistent with pictures displayed. But the platform is willing to
        provide assistance to the user to negotiate a refund, while the ultimate choice goes to the seller itself.</p>
      <p>· Any
        issues caused by the users’ unfamiliarity with local laws, including mistakenly buying forbidden products on the
        platform (including, without limitation, jewelry, coins, money, liquid, living animal and plants, pets, weapons,
        tobacco products, gambling equipment, communication equipment.) imported to the country. However, the platform is
        willing to remind users when buying products, and the ultimate responsibility goes to users themselves. </p>
      <p>· Losses
        caused by the unlawful sale of pirated, and counterfeit products.</p>
      <p>· Any delayed delivery of products, due to long
        holidays, weather, customs inspection, Force Majeure, etc.
      </p>
      <p style="margin-bottom: 20px;">· Wrong purchases or wrong assembly.</p>
      <p> 2. This platform is not responsibleforand shall have no liability to any direct or indirect damages to the use
        of
        services.</p>
      <p> 3. The platform is not responsible for, and shall have no liability to mistaken deletion or unsuccessful storage
        of
        the information released by users.</p>
      <p>4. Users shall bear full responsibility for the information provided by themselvesin accordance with the law.</p>
      <p class="mar40"> 5. Any personal opinions DO NOT represent the platform’s stand. Furthermore, the platform shall
        not assume any
        responsibility caused by the network service interruption or other defects caused by force majeure or hackers,
        disconnecting users from the APP, but we promise to try to reduce the losses or impact on users.
      </p>
      <h3>Attention</h3>
      <p>
        1. Users are supposed to stay in contact when using the service of RAKUMART. Otherwise, Users shall take all the
        risks during disconnection.</p>
      <p class="mar40"> 2. If there are any doubts about the quality of the goods, the user needs to send complaints to
        the
        platform
        within
        15days after the purchasing goods are delivered to you. Noticeably, the platform will not process overdue
        complaints.</p>

      <h3>Service Amendment and Termination</h3>
      <p> 1.RAKUMART may amend any Terms at any time by posting the relevant amended and restated Terms on The Platform.
        If
        not consenting to the changes, users shallnotify the service center of the platform to put an end to its
        service. By
        continuing to access or use the services or the sites, you agree that the amended and restated Terms will apply to
        you. We reserve the right to, without prior notice, suspend or terminate the use of services. The platform does
        not
        hold the responsibility to users and third parties when exercising the termination rights.</p>
      <p>2.The platform may suspend or terminate one or more services at any time depending on the actual situation
        without
        assuming any liability to any user or third parties. If don’t see eye to eye with the suspension or termination of
        network service, please notifytheservice center to confirm the cessation of the service of the platform.</p>
      <p> 3.For users who violate the Terms, the platform has the right to suspend or terminate the services provided to
        the
        user at any time without notifying or without any liability to the user or any third party.</p>
      <p> 4.Any newly-added functions or tools on The Platform are bound by the Terms.</p>
      <p class="mar40">
        5.Make sure to check new changes of the Term regularly, and we will notify you of the new amendment via RAKUMART
        notifications and e-mail. By continuing to access or use the services or the sites, you agree that the amended and
        restated Terms will apply to you.</p>
      <h3>Notices</h3>
      <p> 1.Any dispute arising from or involved in theTerms is expected to be settled through friendly negotiation. We
        may
        cancel or terminate your member account if you appeal to third parties (including, without limitation, third party
        service providers).</p>
      <p> 2.RAKUMART offers you speedy disputes solutions, while you have to provide information hereunder: (1) Pictures
        and
        videos that can prove product damages. (2) Send relevant videos to us if there is no picture proof. (3)
        Screenshots
        of e-mails about disputes, including name, date, and content.</p>
      <p> 3.If the negotiation fails, the user agrees to submit the dispute to Hangzhou Arbitration Commission of Zhejiang
        Province for arbitration and settlement; the dispute settlement in the Termsshall apply to the arbitration rules
        of
        Hangzhou Arbitration Commission. According to the Independence Principle of the Arbitration Terms, it remains
        effective despite the conclusion, entry into force, interpretation, amendment, supplement, termination,
        execution,and dispute resolution of the Terms.</p>
      <p> 4. If any provision of this Termsis held to be invalid or unenforceable, the remaining provisions are still
        effective which both parties shall be bound by.</p>
      <p>5. The right of final interpretation of the Termsbelongs to RAKUMART.
      </p>
      <div class="footer">
        <div class="left">If RAKUMART has posted or provided a translation of the English language version of the Terms,
          you agree that the translation is provided for convenience only and that the English language version will
          govern your access to and use of the services of the platform.</div>
        <div class="right">
          <p>Effective Date: now</p>
          <p>Validity Period: Long-term validity</p>
        </div>
      </div>





















    </div>
    <Foot />
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot";
export default {
  data() {
    return {};
  },
  components: {
    Head,
    Foot,
  },
  methods: {

  },
};
</script>
<style lang='scss' scoped>
.poundage {
  background-color: white;
}

.main {
  width: 1000px;
  margin: auto;
  margin-top: -25px;
  padding-top: 85px;
  padding-bottom: 81px;

  h2 {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #222222;
    margin-bottom: 48px;
  }

  h3 {
    width: 100%;
    font-size: 21px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #222222;
    margin-bottom: 20px;
  }

  h4 {
    width: 100%;
    font-size: 16px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #222222;
    margin-bottom: 10px;
  }

  p {
    width: 100%;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
  }

  .marleft {
    padding-left: 20px;
    margin-bottom: 8px;
  }

  .mar40 {
    margin-bottom: 40px;
  }

  .footer {

    margin-top: 30px;
    display: flex;
    justify-content: space-between;


    .left {
      width: 600px;
      height: 60px;
      font-size: 13px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #999999;
      line-height: 20px;

    }

    .right {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
      line-height: 24px;

      p {
        text-align: right;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  margin-bottom: 20px;
}
</style>
